textarea { 
  width: 100%;
  margin-bottom: 1rem;
  min-height: auto;
  height: 230px;
  resize: none;
  font-family: monospace;
  font-size: 11px;
  line-height: 15px;
}

body {
  background-color: #ecf0f3 !important;
}